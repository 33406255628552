<script setup>
/**
 * Import packages
 */
import { defineComponent, ref } from 'vue';
import {
  PhHouse,
  PhCalendarBlank,
  PhClipboardText,
  PhTable,
  PhChartBar,
  PhNewspaper,
  PhSquaresFour,
  PhDotsThreeOutlineVertical,
} from '@phosphor-icons/vue';

/**
 * Import components
 */
import MainMenuItem from '@/components/ui/MainMenuItem.vue';

/**
 * Component Name
 */
defineComponent({
  name: 'MainMenu',
});

/**
 * Variables
 */
const menuItems = [
  { name: 'Úvod', routeName: 'Home', icon: PhHouse },
  { name: 'Zápasy', routeName: 'Fixtures', icon: PhCalendarBlank },
  { name: 'Výsledky', routeName: 'Results', icon: PhClipboardText },
  { name: 'Tabuľka', routeName: 'Table', icon: PhTable },
  { name: 'Štatistiky', routeName: 'Statistics', icon: PhChartBar },
  { name: 'Ost. Kategórie', routeName: 'Categories', icon: PhSquaresFour },
];

const showItems = ref(false);
</script>

<template>
  <ul class="main-menu">
    <input v-model="showItems" type="checkbox" class="main-menu__checkbox" />
    <MainMenuItem :menu-items="menuItems" />
    <li class="main-menu__item main-menu__item--more">
      <button class="main-menu__item__button" @click="showItems = !showItems">
        <PhDotsThreeOutlineVertical weight="duotone" class="main-menu__item__button__icon" /> Viac
      </button>
    </li>
  </ul>
</template>
