/**
 * Import packages
 */
import { defineStore, acceptHMRUpdate } from 'pinia';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

import postsAPI from '@/api/posts.js';

/**
 * Define Store
 */
export const usePostsStore = defineStore({
  /**
   * Store Name
   */
  id: 'posts',

  /**
   * States
   */
  state: () => ({
    posts: [],
    post: [],
    randomPhotos: [],
    selectedPhotos: [],
    isLoading: false,
    hasError: false,
    errors: [],
  }),

  /**
   * Getters
   */
  getters: {
    publishedArticles(state) {
      if (isEmpty(state.posts)) return;

      return state.posts.data.filter(post => post.publishedAt && dayjs(post.publishedAt) <= dayjs());
    },
    latestPublishedArticles(state) {
      if (isEmpty(state.posts)) return;

      return state.posts.data.filter(post => post.publishedAt && dayjs(post.publishedAt) <= dayjs()).splice(0, 3);
    },
    // results(state) {
    //   return groupMatchesByWeek(state.matches.filter(match => match.closed && match.teams.length === 2).reverse());
    // },
    // fixtures(state) {
    //   return groupMatchesByWeek(
    //     // state.matches.filter(match => !match.closed && new Date(match.startDate) >= new Date())
    //     state.matches.filter(match => !match.closed && match.teams.length === 2)
    //   );
    // },
    // getTeamMatches: state => teamName =>
    //   state.matches.filter(match => match.teams.length === 2 && match.teams.some(team => team.name === teamName)),
  },

  /**
   * Actions
   */
  actions: {
    async fetchPosts() {
      try {
        // Fetch the first batch of matches from the API
        let response = await postsAPI.fetchPosts();

        this.posts = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async createPost(post) {
      try {
        // Fetch the first batch of matches from the API
        let response = await postsAPI.createPost(post);

        this.$router.push({ name: 'Admin/Articles' });

        console.log(response);
      } catch (error) {
        this.setError(error);
      } finally {
        // Set the loading state to false after the API request is complete
      }
    },
    async fetchPost(postId) {
      try {
        // Fetch the first batch of matches from the API
        let response = await postsAPI.fetchPost(postId);

        this.post = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updatePost(postId, data) {
      try {
        // Fetch the first batch of matches from the API
        let response = await postsAPI.updatePost(postId, data);

        this.post = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async deletePost(postId) {
      try {
        // Fetch the first batch of matches from the API
        let response = await postsAPI.deletePost(postId);

        let postIndex = this.posts.data.map(item => item.id).indexOf(postId);

        this.posts.data.splice(postIndex, 1);

        this.post = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async publishPost(postId, publishedAt) {
      try {
        // Fetch the first batch of matches from the API
        let response = await postsAPI.publishPost(postId, publishedAt);

        this.post = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchRandomPhotos() {
      try {
        // Fetch the first batch of matches from the API
        let response = await postsAPI.fetchRandomPhotos();

        this.randomPhotos = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async addPhotosToPost(postId, photos) {
      try {
        // Fetch the first batch of matches from the API
        let response = await postsAPI.addPhotosToPost(postId, photos);

        this.post = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async setFeaturedPhotoForPost(postId, photo, value) {
      try {
        // Fetch the first batch of matches from the API
        await postsAPI.setFeaturedPhotoForPost(postId, photo, value);

        await this.fetchPost(postId);
      } catch (error) {
        console.log(error);
      }
    },
    async cropFeaturedPhotoForPost(postId, data) {
      try {
        // Fetch the first batch of matches from the API
        await postsAPI.cropFeaturedPhotoForPost(postId, data);

        await this.fetchPost(postId);
      } catch (error) {
        console.log(error);
      }
    },
    async deletePhotosFromPost(postId, photos) {
      try {
        // Fetch the first batch of matches from the API
        await postsAPI.deletePhotosFromPost(postId, photos);

        await this.fetchPost(postId);
      } catch (error) {
        console.log(error);
      }
    },
    addToSelectedPhoto(path) {
      this.selectedPhotos.push(path);
    },
    removeFromSelectedPhoto(path) {
      let photoIndex = this.selectedPhotos.indexOf(path);

      console.log(photoIndex);

      if (photoIndex !== -1) {
        this.selectedPhotos.splice(photoIndex, 1);
      }
    },
    setIsLoading(loading) {
      this.isLoading = loading;
    },
    setHasError(error) {
      this.hasError = error;
    },
    setError(source, error) {
      this.setHasError(true);
      this.errors.push({ 'api: ': source, 'error: ': error });
    },
    /**
     * Resets stores state
     * @param {string} state Name of the state to be reseted
     * @param {string} value To which value it shoudl be reseted
     */
    $reset(state, value) {
      this[state] = value;
    },
  },
});

/**
 * Import HMR
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePostsStore, import.meta.hot));
}
