<script setup>
/**
 * Import packages
 */
import { onMounted } from 'vue';
import { defineComponent, ref, useSlots, provide } from 'vue';

/**
 * Component Name
 */
defineComponent({
  name: 'VTabs',
});

/**
 * Component Props
 */
const props = defineProps({
  selectTitle: {
    type: Number,
    default: 0,
  },
});

const slots = useSlots();
const tabTitles = ref(slots.default().map(tab => tab.props?.title));
let selectedTitle = ref(tabTitles.value[0]);

onMounted(() => {
  if (props.selectTitle) selectedTitle.value = tabTitles.value[props.selectTitle];
});

provide('selectedTitle', selectedTitle);
</script>

<template>
  <div class="tabs">
    <ul class="tabs__header">
      <template v-for="title in tabTitles" :key="title">
        <li
          v-if="title"
          class="tabs__header__item"
          :class="{ 'tabs__header__item--selected': selectedTitle === title }"
          @click="selectedTitle = title"
        >
          {{ title }}
        </li></template
      >
    </ul>
    <slot />
  </div>
</template>
