<script setup>
/**
 * Import packages
 */
import { defineComponent } from 'vue';

/**
 * Component Name
 */
defineComponent({
  name: 'VTableRow',
});
</script>

<template>
  <div class="v-table-row">
    <slot />
  </div>
</template>
