/**
 * Import packages
 */
import { defineStore, acceptHMRUpdate } from 'pinia';
import { has, hasIn, isEmpty } from 'lodash';

import replaceTeamName from '@/helpers/replaceTeamName';
import getDayOfYear from '@/utils/getDayOfYear';

const teamID = import.meta.env.VITE_API_SPORTNET_TEAM_ID;

import competitionAPI from '@/api/competition.js';
/**
 * Define Store
 */
export let useCompetitionStore = defineStore({
  /**
   * Store Name
   */
  id: 'competition',

  /**
   * States
   */
  state: () => ({
    clubCompetitions: {},
    competition: {},
    seasons: {},
    isLoading: true,
    hasError: false,
    errors: [],
  }),

  /**
   * Getters
   */
  getters: {
    getSeasons(state) {
      return [...new Set(state.seasons.map(item => item.name))];
    },
    getRounds(state) {
      if (!has(state.competition, 'rounds')) return [];

      return state.competition.rounds;
    },
    getPreviousRound() {
      if (!isEmpty(this.getRounds)) return;

      const now = new Date();
      for (let i = this.getRounds.length - 1; i >= 0; i--) {
        const dateTo = new Date(this.getRounds[i].dateTo);
        if (dateTo < now) {
          return this.getRounds[i - 2];
        }
      }
      return null;
    },
    getLastRound() {
      if (isEmpty(this.getRounds)) return;

      const now = new Date();
      for (let i = this.getRounds.length - 1; i >= 0; i--) {
        const dateTo = new Date(this.getRounds[i].dateTo);
        if (dateTo < now) {
          return this.getRounds[i];
        }
      }
      return null;
    },
    playersStatistics(state) {
      if (isEmpty(state.competition)) return;

      return state.competition.resultsTable.players.map(player => {
        const playersTeam = replaceTeamName(this.getTeamNameByTeamId(player.teamId));
        const playersTeamLogo = this.getTeamLogoByTeamId(player.teamId);

        return {
          ...player,
          playersTeam,
          playersTeamLogo,
        };
      });
    },
    teams(state) {
      if (!hasIn(state.competition, 'teams')) return null;

      let teams = state.competition.teams
        .filter(team => !team.resignation)
        .map(team => ({
          name: replaceTeamName(team.name),
          logo: team.organization.logo_public_url,
        }));

      return teams;
    },
    autumnDates(state) {
      const start = state.competition.season.dateFrom;
      const end = getDayOfYear(start, true);

      return { start: start, end: end };
    },
    springDates(state) {
      const end = state.competition.season.dateTo;
      const start = getDayOfYear(end, false);

      return { start: start, end: end };
    },
    getTeamByName: state => {
      if (!hasIn(state.competition, 'teams')) return null;

      return teamName => state.competition.teams.find(team => team.name === teamName);
    },
    getPlayersStatsByTeamId: state => {
      if (!hasIn(state.competition, 'resultsTable')) return null;

      return teamId => state.competition.resultsTable.players.filter(player => player.teamId === teamId);
    },
    getTeamNameByTeamId: state => {
      if (!hasIn(state.competition, 'teams')) return null;

      return teamId => state.competition.teams.find(team => team._id === teamId).name;
    },
    getTeamLogoByTeamId: state => {
      if (!hasIn(state.competition, 'teams')) return null;

      return teamId => state.competition.teams.find(team => team._id === teamId).organization.logo_public_url;
    },
    currentSeasonId(state) {
      return state.competition.season._id;
    },
    //   currentSeasonCompetitions(state) {
    //     if (!state.clubCompetitions.competitions) return [];

    //     const competitions = state.clubCompetitions.competitions.filter(
    //       competition => competition.season._id === this.currentSeasonId
    //     );

    //     return competitions
    //       .map(competition => ({
    //         name: competition.name,
    //         teams: competition.parts
    //           .filter(part => part.teams.every(team => team._id !== teamID))
    //           .map(part => ({
    //             partId: part._id,
    //             teamId: part.teams[0]._id,
    //             teamName: part.teams[0].organization.name,
    //             teamDisplayName: part.teams[0].displayName,
    //           })),
    //       }))
    //       .filter(competition => competition.teams.length > 0);
    //   },
    currentSeasonCompetitions(state) {
      if (!state.clubCompetitions.competitions) return [];

      const filteredTeams = state.clubCompetitions.competitions
        .filter(competition => competition.season._id === this.currentSeasonId)
        .flatMap(competition =>
          competition.parts.flatMap(part =>
            part.teams
              .filter(team => team._id !== teamID)
              .map(team => ({
                competitionId: competition._id,
                partId: part._id,
                teamId: team._id,
                teamName: team.organization.name,
                teamDisplayName: team.displayName,
              }))
          )
        );

      return filteredTeams;
    },
  },

  /**
   * Actions
   */
  actions: {
    /**
     * Asynchronously current competition data from the competitionAPI,
     * sets the competition state to the response data.
     */
    async fetchClubCompetitions() {
      try {
        const response = await competitionAPI.fetchClubCompetitions();
        this.clubCompetitions = response.data;
      } catch (error) {
        this.setError('clubCompetitions', error);
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Asynchronously current competition data from the competitionAPI,
     * sets the competition state to the response data.
     */
    async fetchCompetition() {
      try {
        const response = await competitionAPI.fetchCompetition();
        this.competition = response.data;
      } catch (error) {
        this.setError('competition', error);
        setTimeout(() => {
          this.setIsLoading(false);
        }, 2000);
      }
    },
    /**
     * Asynchronously current competition data from the competitionAPI,
     * sets the competition state to the response data.
     */
    async fetchCurrentCompetition() {
      try {
        const response = await competitionAPI.fetchCurrentCompetition();
        this.competition = response.data;
      } catch (error) {
        this.setError('competition', error);
        setTimeout(() => {
          this.setIsLoading(false);
        }, 2000);
      }
    },
    /**
     * Asynchronously fetches seasons data from the competitionAPI,
     * sets the seasons state to the reversed array of seasons data from the response data.
     */
    async fetchSeasons() {
      try {
        const response = await competitionAPI.fetchSeasons();
        this.seasons = response.data.seasons.reverse();
      } catch (error) {
        this.setError('seasons', error);
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     *Sets the value of the isLoading property in the store.
     *@param {Boolean} loading - The boolean value to set the isLoading property to.
     */
    setIsLoading(loading) {
      this.isLoading = loading;
    },
    /**
     * Sets the value of the hasError property in the store.
     * @param {Boolean} error - The boolean value to set the hasError property to.
     */
    setHasError(error) {
      this.hasError = error;
    },
    /**
     * Sets the hasError property to true and pushes a new error object to the errors array.
     * @param {String} source - A string identifying the source of the error.
     * @param {Error} error - The error object to add to the errors array.
     */
    setError(source, error) {
      this.setHasError(true);
      this.errors.push({ 'api: ': source, 'error: ': error });
    },
  },
});

/**
 * Checks if the current environment supports hot module replacement.
 * If it does, it calls the import.meta.hot.accept() method to accept hot module replacement updates for the useCompetitionStore module.
 * The acceptHMRUpdate() function is called with useCompetitionStore and import.meta.hot as arguments to enable HMR for the useCompetitionStore module.
 * This allows for the store to be updated in real time during development without requiring a full page refresh.
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompetitionStore, import.meta.hot));
}
