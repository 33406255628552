/**
 * Imports
 */
import { defineStore, acceptHMRUpdate } from 'pinia';
import { isEmpty } from 'lodash';

import replaceTeamName from '@/helpers/replaceTeamName';

import tableAPI from '@/api/table.js';

import { useCompetitionStore } from '@/stores/competition';
import { useMatchesStore } from '@/stores/matches';

/**
 * Functions
 */
const getIndexForNearestOrCurrentDate = arr => {
  const currentDate = new Date().toISOString(); // get current date

  const index = arr.findIndex(obj => obj.startDate >= currentDate); // find index of first object with start date equal to or greater than current date

  if (index === -1) return null; // if no objects with start date equal to or greater than current date, return null

  const filteredArr = arr.filter(obj => obj.startDate >= currentDate); // filter array to include only objects with start date equal to or greater than current date

  const nearestDate = filteredArr.reduce((prev, curr) => (prev.startDate < curr.startDate ? prev : curr)); // get object with nearest start date

  return nearestDate; // return index of object with nearest start date
};

/**
 * Define Store
 */
export let useTableStore = defineStore({
  /**
   * Store Name
   */
  id: 'table',

  /**
   * States
   */
  state: () => ({
    data: [],
    table: {},
    resigned: {},
    previousTable: {},
    isLoading: true,
    hasError: false,
    errors: [],
  }),

  /**
   * Getters
   */
  getters: {
    newTable(state) {
      if (isEmpty(state.table)) return;

      if (isEmpty(state.previousTable)) return state.table;

      return state.table.map(team => {
        const competitionStore = useCompetitionStore();
        const matchesStore = useMatchesStore();

        if (isEmpty(this.previousTable)) return;

        const previousTeam = this.previousTable.find(t => t.team.name === team.team.name);
        const previousPosition = previousTeam ? state.previousTable.indexOf(previousTeam) + 1 : null;

        const teamLogo = competitionStore.getTeamByName(team.team.name)?.organization.logo_public_url;

        const nextMatch = getIndexForNearestOrCurrentDate(
          matchesStore.matches.filter(match => {
            return match.teams.some(t => t.name === team.team.name);
          })
        );

        return {
          ...team,
          previousPosition,
          teamLogo,
          nextMatch,
        };
      });
    },
  },

  /**
   * Actions
   */
  actions: {
    async fetchTable(competitionId, partId, roundId) {
      if (isEmpty(this.table)) this.loading = true;

      try {
        const response = await tableAPI.fetchTable(competitionId, partId, roundId);
        if (roundId) {
          this.previousTable = response.data.results.filter(team => !team.team.resignation);
          this.resigned = response.data.results
            .filter(team => team.team.resignation)
            .map(item => {
              const modifiedTeamName = replaceTeamName(item.team.name);
              return { ...item, team: { ...item.team, name: modifiedTeamName } };
            });
        } else {
          this.table = response.data.results.filter(team => !team.team.resignation);
        }
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTableForTeam(teamId) {
      try {
        const response = await tableAPI.fetchTableForTeam(teamId);
        this.data = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    setIsLoading(loading) {
      this.isLoading = loading;
    },
    setHasError(error) {
      this.hasError = error;
    },
    setError(source, error) {
      this.setHasError(true);
      this.errors.push({ 'api: ': source, 'error: ': error });
    },
  },
});

/**
 * Import HMR
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTableStore, import.meta.hot));
}
