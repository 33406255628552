/**
 * Import packages
 */
import { defineStore, acceptHMRUpdate } from 'pinia';
import { isEmpty } from 'lodash';

import matchesAPI from '@/api/matches.js';

/**
 * Define Store
 */
export const useMatchStore = defineStore(
  {
    /**
     * Store Name
     */
    id: 'match',

    /**
     * States
     */
    state: () => ({
      match: {},
      isLoading: true,
      hasError: false,
      errors: [],
    }),

    /**
     * Getters
     */
    getters: {
      // results(state) {
      //   return groupMatchesByWeek(state.matches.filter(match => match.closed && match.teams.length === 2).reverse());
      // },
      // fixtures(state) {
      //   return groupMatchesByWeek(
      //     // state.matches.filter(match => !match.closed && new Date(match.startDate) >= new Date())
      //     state.matches.filter(match => !match.closed && match.teams.length === 2)
      //   );
      // },
      // getTeamMatches: state => {
      //   return teamName =>
      //     state.matches.filter(match => {
      //       return match.teams.some(team => team.name === teamName);
      //     });
      // },
      // homeTeam(state) {
      //   if (isEmpty(state.match)) return;
      //   return state.match.teams[0];
      // },
      // homeTeamEvents(state) {
      //   if (isEmpty(state.match)) return;
      //   return state.match.protocol.events.filter(event => event.team === this.homeTeam._id);
      // },
      // homeTeamGoals(state) {
      //   if (isEmpty(state.match)) return;
      //   return this.homeTeamEvents.filter(event => event.eventType === 'goal');
      // },
      // awayTeam(state) {
      //   if (isEmpty(state.match)) return;
      //   return state.match.teams[1];
      // },
      // awayTeamEvents(state) {
      //   if (isEmpty(state.match)) return;
      //   return state.match.protocol.events.filter(event => event.team === this.awayTeam._id);
      // },
      // awayTeamGoals(state) {
      //   if (isEmpty(state.match)) return;
      //   return this.awayTeamEvents.filter(event => event.eventType === 'goal');
      // },
    },

    /**
     * Actions
     */
    actions: {
      /**
       * Fetches a single match with the given ID from the API, sets the match state to the response data, and handles errors by setting
       * the match state to an error object with a message indicating that the ID is not valid.
       * @param {String} matchId The ID of the match to fetch.
       */
      async fetchMatch(matchId) {
        try {
          // Make the API request to fetch the match with the given ID
          const response = await matchesAPI.fetchMatch(matchId);

          // Set the match state to the response data
          this.match = response.data;
        } catch (error) {
          // If the API request fails, set the match state to an error object with a message indicating that the match does not exist
          this.match = { error: `Zápas s ID ${matchId} neexistuje.` };
        }
      },
      async createMatch(matchId, postId) {
        try {
          const response = await matchesAPI.fetchMatch(matchId);

          const match = response.data;

          match.postId = postId;

          console.log(match);

          if (!isEmpty(match)) {
            let response = await matchesAPI.createMatch(match);

            console.log(response);
          }
        } catch (error) {
          console.log(error);
        } finally {
          // Set the loading state to false after the API request is complete
        }
      },
      setIsLoading(loading) {
        this.isLoading = loading;
      },
      setHasError(error) {
        this.hasError = error;
      },
      setError(source, error) {
        this.setHasError(true);
        this.errors.push({ 'api: ': source, 'error: ': error });
      },
      /**
       * Resets stores state
       * @param {string} state Name of the state to be reseted
       * @param {string} value To which value it shoudl be reseted
       */
      $reset(state, value) {
        this[state] = value;
      },
    },
  },
  {
    // this will be read by a plugin later on
    debounce: {
      // debounce the action searchContacts by 300ms
      fetchMatches: 3000,
    },
  }
);

/**
 * Import HMR
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMatchStore, import.meta.hot));
}
