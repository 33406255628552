<script setup>
/**
 * Import packages
 */
import { defineComponent } from 'vue';
import { PhCaretRight } from '@phosphor-icons/vue';

/**
 * Component Name
 */
defineComponent({
  name: 'VBox',
});

/**
 * Component Props
 */
defineProps({
  title: {
    type: String,
    required: false,
    default: '',
  },
  hideHeader: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasBorder: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasColoredBorder: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasWhiteBackground: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasColoredBackground: {
    type: Boolean,
    required: false,
    default: false,
  },
  link: {
    type: String,
    required: false,
    default: null,
  },
});
</script>

<template>
  <div
    :class="[
      {
        'box--border': hasBorder,
        'box--colored-border': hasColoredBorder,
        'box--white-background': hasWhiteBackground,
        'box--colored-background': hasColoredBackground,
      },
      'box',
    ]"
  >
    <div :class="[{ 'box__header--hidden': hideHeader }, 'box__header']">
      <h3 class="box__header__title">{{ title }}</h3>
      <router-link v-if="link" :to="{ name: link }" class="box__header__link"
        >Všetky {{ title }}
        <PhCaretRight weight="bold" class="box__header__link__icon" />
      </router-link>
    </div>

    <div class="box__content">
      <slot />
    </div>
  </div>
</template>
