import axios from 'axios';

export default (url = import.meta.env.VITE_API_URL) => {
  return axios.create({
    baseURL: url,
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem('tjolAuthToken'),
    },
  });
};

// Define the sportNetApi function that returns an axios instance
export const sportNetApi = (url = import.meta.env.VITE_API_SPORTNET_URL) => {
  return axios.create({
    baseURL: url,
    headers: {
      'Content-type': 'application/json',
    },
  });
};
