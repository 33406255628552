<script setup>
/**
 * Import packages
 */
import { defineComponent } from 'vue';

import ASource from '@/components/pages/ASource.vue';

/**
 * Component Name
 */
defineComponent({
  name: 'MainHeader',
});

/**
 * Variables
 */
const title = import.meta.env.VITE_APP_NAME;
const description = import.meta.env.VITE_APP_DESCRIPTION;
</script>

<template>
  <div class="main-header">
    <img class="main-header__logo" src="@/assets/images/teams/100/tj-oravska-lesna.webp" />
    <h1 class="main-header__title">{{ title }}</h1>
    <div class="main-header__description">{{ description }}</div>
    <ASource url="https://sportnet.sme.sk/futbalnet/" name="Spornet / Futbalnet" />
  </div>
</template>
