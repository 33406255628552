/**
 * Import packages
 */
import { createRouter, createWebHistory } from 'vue-router';

import {
  fetchAll,
  fetchCompetition,
  fetchMatches,
  fetchTable,
  fetchRandomPhotos,
  fetchPost,
  fetchPosts,
  fetchMatch,
} from '@/router/guards/fetchData';
import registerCodeGuard from '@/router/guards/registerCode';
import authGuard from '@/router/guards/auth';
import storeGuard from '@/router/guards/store';

// import { useMembersStore } from '@/stores/members';

/**
 * Variables
 */
// const competitionStore = useCompetitionStore();
// const matchesStore = useMatchesStore();
// const tableStore = useTableStore();

/**
 * Define routes
 */
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    // beforeEnter: [fetchTable, fetchMatches],
    meta: {
      title: null,
      scrollToTop: true,
    },
  },
  {
    path: '/tabulka',
    name: 'Table',
    component: () => import('@/views/Table.vue'),
    // beforeEnter: [fetchTable, fetchMatches],
    meta: {
      title: 'Tabuľka',
      scrollToTop: true,
    },
  },
  {
    path: '/zapasy',
    name: 'Fixtures',
    component: () => import('@/views/Fixtures.vue'),
    // beforeEnter: fetchMatches,
    meta: {
      title: 'Zápasy',
      scrollToTop: true,
    },
  },
  {
    path: '/zapas/:matchId',
    sensitive: true,
    name: 'Match',
    component: () => import('@/views/Match.vue'),
    beforeEnter: fetchMatch,
    meta: {
      title: 'Zápas',
      scrollToTop: true,
    },
  },
  {
    path: '/vysledky',
    name: 'Results',
    component: () => import('@/views/Results.vue'),
    // beforeEnter: fetchMatches,
    meta: {
      title: 'Výsledky',
      scrollToTop: true,
    },
  },
  {
    path: '/statistiky',
    name: 'Statistics',
    component: () => import('@/views/Statistics.vue'),
    // beforeEnter: fetchCompetition,
    meta: {
      title: 'Štatistiky',
      scrollToTop: true,
    },
  },
  {
    path: '/ostatne-kategorie',
    name: 'Categories',
    component: () => import('@/views/Categories.vue'),
    // beforeEnter: fetchCompetition,
    meta: {
      title: 'Ostatné kategórie',
      scrollToTop: true,
    },
  },
  {
    path: '/novinky',
    sensitive: true,
    name: 'News',
    component: () => import('@/views/Posts.vue'),
    beforeEnter: [fetchPosts, fetchRandomPhotos],
    meta: {
      title: 'Novinky',
      scrollToTop: true,
    },
  },
  {
    path: '/novinka/:postId',
    sensitive: true,
    name: 'Post',
    component: () => import('@/views/Post.vue'),
    beforeEnter: fetchPost,
    meta: {
      title: 'Novinka',
      scrollToTop: true,
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    redirect: { name: 'Admin/Dashboard' },
    children: [
      {
        path: 'login',
        name: 'Admin/Login',
        component: () => import('@/views/admin/Login.vue'),
        meta: {
          title: 'Admin - Login',
        },
      },
      {
        path: 'register',
        name: 'Admin/Register',
        component: () => import('@/views/admin/Register.vue'),
        beforeEnter: registerCodeGuard,
        meta: {
          title: 'Admin - Register',
        },
      },
      {
        path: 'dashboard',
        name: 'Admin/Dashboard',
        component: () => import('@/views/admin/Dashboard.vue'),
        meta: {
          title: 'Admin - Dashboard',
          requiresAuth: true,
        },
      },
      {
        path: 'data',
        name: 'Admin/Data',
        component: () => import('@/views/admin/Data.vue'),
        beforeEnter: fetchCompetition,
        meta: {
          title: 'Admin - Data',
          requiresAuth: true,
        },
      },
      {
        path: 'articles',
        name: 'Admin/Articles',
        component: () => import('@/views/admin/Articles.vue'),
        beforeEnter: fetchPosts,
        meta: {
          title: 'Admin - Articles',
          requiresAuth: true,
        },
      },
      {
        path: 'create-article',
        name: 'Admin/CreateArticle',
        component: () => import('@/views/admin/CreateArticle.vue'),
        meta: {
          title: 'Admin - Create Article',
          requiresAuth: true,
        },
      },
      {
        path: 'edit-article/:postId',
        sensitive: true,
        name: 'Admin/EditArticle',
        component: () => import('@/views/admin/EditArticle.vue'),
        beforeEnter: fetchPost,
        meta: {
          title: 'Admin - Edit Article',
          requiresAuth: true,
        },
      },
      {
        path: 'members',
        name: 'Admin/Members',
        component: () => import('@/views/admin/Members.vue'),
        beforeEnter: () => {
          const membersStore = useMembersStore();
          membersStore.fetchItems();
        },
        meta: {
          title: 'Admin - Members',
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      title: 'Stránka nebola nájdená',
      scrollToTop: true,
    },
  },
];

/**
 * Create Router
 */
const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_URL),

  scrollBehavior(to) {
    if (to.meta.scrollToTop) {
      return { top: 0, left: 0 };
    }
  },

  linkActiveClass: 'main-menu__item__link--active',
  linkExactActiveClass: 'main-menu__item__link--exact-active',

  routes,
});

router.beforeEach(fetchAll);
router.beforeEach(authGuard);
router.beforeEach(storeGuard);

export default router;
