// Define an array of objects representing strings to be replaced and their replacements
const defaultNamesToReplace = [
  { find: 'Telovýchovná jednota', replace: 'TJ' },
  { find: 'Športový klub', replace: 'ŠK' },
  { find: 'SIHELNÉ', replace: 'Sihelné' },
  { find: 'LOKOMOTÍVA', replace: 'Lokomotíva' },
  { find: 'Futbalový klub', replace: 'FK' },
];

/**
 * Replaces strings in an input string with replacements from an array of objects.
 *
 * @param {String} originalString - The input string to modify.
 * @param {Object[]} [replacements=replacements] - An array of objects representing strings to be replaced and their replacements.
 * @returns {String} - The modified input string.
 */
const replaceTeamName = (originalString, replacements = defaultNamesToReplace) => {
  let modifiedString = originalString;

  for (let i = 0; i < replacements.length; i++) {
    modifiedString = modifiedString.replace(replacements[i].find, replacements[i].replace);
  }

  return modifiedString;
};

// Export the replaceStringsInString function as the default export of this module
export default replaceTeamName;
