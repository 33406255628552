<script setup>
/**
 * Import packages
 */
import { defineComponent } from 'vue';

/**
 * Component Name
 */
defineComponent({
  name: 'MainMenuItem',
});

/**
 * Component Props
 */
defineProps({
  menuItems: {
    type: Array,
    default: () => [],
  },
});
</script>

<template>
  <li v-for="item in menuItems" :key="item" class="main-menu__item">
    <RouterLink :to="{ name: item.routeName }" class="main-menu__item__link">
      <Component :is="item.icon" weight="duotone" class="main-menu__item__link__icon" />
      <span class="main-menu__item__link__text">{{ item.name }}</span>
    </RouterLink>
  </li>
</template>
