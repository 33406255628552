/**
 * Higher-order function to generate common actions for stores
 * @param {Object} apiMethods - Object containing API methods for fetching, creating, updating, and deleting items
 * @param {string} itemsKey - Key for the items in the store's state
 * @param {string} itemKey - Key for the individual item in the store's state
 * @returns {Object} - Object containing the dynamically generated actions
 */
export const generateActions = (apiMethods, itemsKey, itemKey, options = {}) => {
  const { redirect = true } = options;

  return {
    /**
     * Action to fetch items from the API
     */
    async fetchItems() {
      try {
        let response = await apiMethods.fetchItems();

        this[itemsKey] = response.data;
      } catch (error) {
        this.setError(error, 'fetchItems');
      }
    },

    /**
     * Action to create an item using the API
     * @param {Object} item - Item to be created
     */
    async createItem(item) {
      try {
        let response = await apiMethods.createItem(item);

        if (redirect) {
          const storeId = this.$id;

          this.$router.push({ name: `Admin/${storeId}` });
        }

        console.log(response);
      } catch (error) {
        this.setError(error, 'createItem');
      }
    },

    /**
     * Action to create an items using the API
     * @param {Object} item - Item to be created
     */
    async createItems(items) {
      try {
        let response = await apiMethods.createItems(items);

        if (redirect) {
          const storeId = this.$id;

          this.$router.push({ name: `Admin/${storeId}` });
        }

        console.log(response);
      } catch (error) {
        this.setError(error, 'createItems');
      }
    },

    /**
     * Action to fetch an individual item from the API
     * @param {string} itemId - ID of the item to fetch
     */
    async fetchItem(itemId) {
      try {
        let response = await apiMethods.fetchItem(itemId);

        this[itemKey] = response.data;
      } catch (error) {
        this.setError(error, 'fetchItem');
      }
    },

    /**
     * Action to update an item using the API
     * @param {Object} payload - Payload containing itemId and data
     *  - itemId: ID of the item to update
     *  - data: Updated data for the item
     */
    async updateItem({ itemId, data }) {
      try {
        let response = await apiMethods.updateItem(itemId, data);

        this[itemKey] = response.data;
      } catch (error) {
        this.setError(error, 'updateItem');
      }
    },

    /**
     * Action to delete an item using the API
     * @param {string} itemId - ID of the item to delete
     */
    async deleteItem(itemId) {
      try {
        let response = await apiMethods.deleteItem(itemId);
        let itemIndex = this[itemsKey].data.map(item => item.id).indexOf(itemId);
        this[itemsKey].data.splice(itemIndex, 1);
        this[itemKey] = response.data;
      } catch (error) {
        this.setError(error, 'deleteItem');
      }
    },
  };
};
