import Api from '.';

export default {
  getUser() {
    return Api().get('/auth/get-user');
  },
  login(user) {
    return Api().post('/auth/login', user);
  },
  register(user) {
    return Api().post('/auth/register', user);
  },
  logout() {
    return Api().post('/auth/logout');
  },
};
