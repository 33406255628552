import Api from '.';

export default {
  fetchErrors() {
    return Api().get(`/errors`);
  },
  createError(error) {
    return Api().post(`/error`, error);
  },
  fetchError(errorId) {
    return Api().get(`/error/${errorId}`);
  },
  updateError(errorId, data) {
    return Api().put(`/error/${errorId}`, data);
  },
  deleteError(errorId) {
    return Api().delete(`/error/${errorId}`);
  },
};
