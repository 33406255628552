import Api from '.';

export default {
  fetchMembers() {
    return Api().get(`/members`);
  },
  fetchMember(memberId) {
    return Api().get(`/member/${memberId}`);
  },
  createMember(data) {
    return Api().post(`/member`, data);
  },
  createMembers(data) {
    return Api().post(`/members`, data);
  },
  updateMember(memberId, data) {
    return Api().put(`/member/${memberId}`, data);
  },
  deleteMember(memberId) {
    return Api().delete(`/member/${memberId}`);
  },
};
