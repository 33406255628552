/**
 * Import packages
 */
import { createApp, markRaw } from 'vue';
import { createHead } from '@vueuse/head';
import { createPinia } from 'pinia';
import { plugin, defaultConfig } from '@formkit/vue';
import config from '@/config/formkit';
import dayjs from 'dayjs';
import VueGtag from 'vue-gtag';
import 'dayjs/locale/sk';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { errorsPlugin } from '@/stores/plugins/errors';

import App from './App.vue';
import router from '@/router';
// import debouncePlugin from '@/stores/plugins/debounce';

import VBox from '@/components/ui/VBox.vue';
import VTab from '@/components/ui/VTab.vue';
import VTabs from '@/components/ui/VTabs.vue';
import VTable from '@/components/ui/VTable.vue';
import VTableBody from '@/components/ui/VTableBody.vue';
import VTableCol from '@/components/ui/VTableCol.vue';
import VTableHead from '@/components/ui/VTableHead.vue';
import VTableRow from '@/components/ui/VTableRow.vue';

dayjs.locale('sk');

/**
 * Clear console after HMR update
 */
if (import.meta.hot) {
  import.meta.hot.on('vite:beforeUpdate', () => console.clear());
}

/**
 * Create store with Pinia
 */
const pinia = createPinia();

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

// Use the errors plugin
pinia.use(errorsPlugin);

/**
 * Create App
 */
const app = createApp(App);

app
  .use(
    VueGtag,
    {
      config: { id: 'G-F8CF7JWMND' },
    },
    router
  )
  .use(pinia)
  .use(router)
  .use(createHead())
  .use(plugin, defaultConfig(config))
  .component('QuillEditor', QuillEditor)
  .component('VBox', VBox)
  .component('VTab', VTab)
  .component('VTabs', VTabs)
  .component('VTable', VTable)
  .component('VTableBody', VTableBody)
  .component('VTableCol', VTableCol)
  .component('VTableHead', VTableHead)
  .component('VTableRow', VTableRow)
  .mount('#app');
