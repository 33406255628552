/**
 * Import packages
 */
import { defineStore, acceptHMRUpdate } from 'pinia';

/**
 * Import apis
 */
import authAPI from '@/api/auth';

/**
 * Define Store
 */
export const useAuthStore = defineStore(
  {
    /**
     * Store Name
     */
    id: 'auth',

    /**
     * States
     */
    state: () => ({
      registerCode: '456789143',
      isAuthenticated: false,
      user: null,
      message: null,
      token: null,
      returnUrl: null,
    }),

    /**
     * Actions
     */
    actions: {
      async fetchUser() {
        try {
          const response = await authAPI.getUser();
          this.user = response.data;
        } catch (error) {
          this.user = null;
          this.setError(error);
          this.clearToken();
          this.$router.push({ name: 'Admin/Login' });
        }
      },

      async register(credentials) {
        try {
          const response = await authAPI.register(credentials);

          this.setMessage(response.data.message);

          await this.login(credentials);
        } catch (error) {
          this.setError(error);
          this.setMessage(JSON.parse(error.response.data));
          this.clearToken();
        }
      },

      async login(credentials) {
        try {
          const response = await authAPI.login(credentials);

          const { access_token, expires_in, message } = response.data;

          this.setToken(access_token, expires_in);
          this.setMessage(message);

          const route = this.returnUrl ? { name: this.returnUrl } : { name: 'Admin/Dashboard' };
          this.$router.push(route);
        } catch (error) {
          this.setError(error);
          this.setMessage(error.response.data.error);
          this.clearToken();
        }
      },

      async logout() {
        try {
          const response = await authAPI.logout();
          this.clearUser();
          this.setMessage(response.data.message);
          this.$router.push({ name: 'Admin/Login' });
        } catch (error) {
          this.setError(error);
        } finally {
          this.clearToken();
        }
      },

      setUser(user) {
        this.user = user;
      },

      clearUser() {
        this.user = null;
      },

      setMessage(user) {
        this.message = user;

        setTimeout(() => (this.message = null), 5000);
      },

      clearMessage() {
        this.message = null;
      },

      setToken(token, expiresIn) {
        this.token = `Bearer ${token}`;

        // Convert expiresIn to milliseconds and calculate expiration timestamp
        const expirationTimestamp = Date.now() + expiresIn * 1000;

        // You may want to persist the token in local storage or cookies
        localStorage.setItem('tjolAuthToken', this.token);
        localStorage.setItem('tjolAuthTokenExpiration', expirationTimestamp);
      },

      clearToken() {
        this.token = null;
        // Clear the persisted token from local storage or cookies
        localStorage.removeItem('tjolAuthToken');
        localStorage.removeItem('tjolAuthTokenExpiration');
      },

      checkAuthTokenExpiration() {
        const currentTimestamp = Date.now();
        const expirationTimestamp = localStorage.getItem('tjolAuthTokenExpiration');

        if (currentTimestamp < Number(expirationTimestamp)) {
          this.isAuthenticated = true;
          this.fetchUser();
        } else {
          this.isAuthenticated = false;
        }
      },
    },
  },
  {
    persist: true,
  }
);

/**
 * Import HMR
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
