<script setup>
/**
 * Import packages
 */
import { defineComponent } from 'vue';
import {
  PhHouse,
  PhNewspaper,
  PhUsersThree,
  PhSignOut,
  PhDotsThreeOutlineVertical,
  PhTreeStructure,
} from '@phosphor-icons/vue';

import MainMenuItem from '@/components/ui/MainMenuItem.vue';

import { useAuthStore } from '@/stores/auth';

/**
 * Component Name
 */
defineComponent({
  name: 'AdminMainMenu',
});

/**
 * Variables
 */

const authStore = useAuthStore();

const menuItems = [
  { name: 'Dashboard', routeName: 'Admin/Dashboard', icon: PhHouse },
  { name: 'Articles', routeName: 'Admin/Articles', icon: PhNewspaper },
  { name: 'Members', routeName: 'Admin/Members', icon: PhUsersThree },
  { name: 'Data', routeName: 'Admin/Data', icon: PhTreeStructure },
];
</script>

<template>
  <ul class="main-menu">
    <MainMenuItem :menu-items="menuItems" />
    <li class="main-menu__item main-menu__item--right">
      <button class="main-menu__item__button" @click="authStore.logout()">
        <PhSignOut weight="duotone" class="main-menu__item__button__icon" /> Log out
      </button>
    </li>
    <li class="main-menu__item main-menu__item--more">
      <button class="main-menu__item__button" @click="showItems = !showItems">
        <PhDotsThreeOutlineVertical weight="duotone" class="main-menu__item__button__icon" /> Viac
      </button>
    </li>
  </ul>
</template>
