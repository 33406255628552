<script setup>
/**
 * Import packages
 */
import { defineComponent } from 'vue';

/**
 * Import components
 */
import MainMenu from '@/components/ui/MainMenu.vue';

/**
 * Import stores
 */
import { useAppStore } from '@/stores/app';

/**
 * Component Name
 */
defineComponent({
  name: 'TheNavigation',
});

const appStore = useAppStore();
</script>

<template>
  <nav :class="[{ 'app__navigation--sticky': !appStore.visibility.header }, 'app__navigation']">
    <div class="app__navigation__inner">
      <MainMenu />
    </div>
  </nav>
</template>
