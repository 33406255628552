import Api from '.';

export default {
  fetchPosts() {
    return Api().get(`/posts`);
  },
  createPost(post) {
    return Api().post(`/post`, post);
  },
  fetchPost(postId) {
    return Api().get(`/post/${postId}`);
  },
  updatePost(postId, data) {
    return Api().put(`/post/${postId}`, data);
  },
  publishPost(postId, publishedAt) {
    return Api().put(`/post/publish/${postId}`, { publishedAt: publishedAt });
  },
  deletePost(postId) {
    return Api().delete(`/post/${postId}`);
  },
  fetchRandomPhotos() {
    return Api().get(`/random-photos`);
  },
  addPhotosToPost(postId, photos) {
    return Api().post(`/post/${postId}/photos`, photos, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  setFeaturedPhotoForPost(postId, photo, value) {
    return Api().put(`/post/${postId}/set-featured-photo`, [photo, value]);
  },
  cropFeaturedPhotoForPost(postId, data) {
    return Api().put(`/post/${postId}/crop-featured-photo`, data);
  },
  deletePhotosFromPost(postId, photos) {
    return Api().put(`/post/${postId}/delete-photos`, photos);
  },
};
