import { defineStore, acceptHMRUpdate } from 'pinia';
// import { isEmpty } from 'lodash';

import membersAPI from '@/api/members.js';
import { generateActions } from '@/helpers/generateActions';

export const useMembersStore = defineStore({
  id: 'members',

  state: () => ({
    members: [],
    newMembers: [],
    member: null,
  }),

  getters: {
    // publishedArticles(state) {
    //   if (isEmpty(state.member)) return;
    //   return state.member.data.filter(member => member.publishedAt);
    // },
    // latestPublishedArticles(state) {
    //   if (isEmpty(state.member)) return;
    //   return state.member.data.filter(member => member.publishedAt).splice(0, 3);
    // },
    // results(state) {
    //   return groupMatchesByWeek(state.matches.filter(match => match.closed && match.members.length === 2).reverse());
    // },
    // fixtures(state) {
    //   return groupMatchesByWeek(
    //     // state.matches.filter(match => !match.closed && new Date(match.startDate) >= new Date())
    //     state.matches.filter(match => !match.closed && match.members.length === 2)
    //   );
    // },
    // getMemberMatches: state => memberName =>
    //   state.matches.filter(match => match.members.length === 2 && match.members.some(member => member.name === memberName)),
  },

  actions: generateActions(
    {
      fetchItems: membersAPI.fetchMembers,
      fetchItem: membersAPI.fetchMember,
      createItem: membersAPI.createMember,
      createItems: membersAPI.createMembers,
      updateItem: membersAPI.updateMember,
      deleteItem: membersAPI.deleteMember,
    },
    'members',
    'member',
    { redirect: false }
  ),

  /**
   * Destructs a nominations object and returns an array of objects with the required properties
   * @param {Object} nominations - The nominations object to destruct
   * @returns {Array<Object>} - An array of objects with the required properties
   */
  destructNominations(nominations) {
    // Check if the `athletes` and `crew` properties exist in the `nominations` object
    const athletes = nominations.athletes || [];
    const crew = nominations.crew || [];

    // Initialize an ID counter and an array to hold the destructed nominations
    let id = 1;
    const destructedNominations = [];

    // Loop through the `athletes` array
    athletes.forEach(({ additionalData, sportnetUser }) => {
      // Create a new object with the required properties for each athlete
      destructedNominations.push({
        id,
        name: sportnetUser.name,
        position: additionalData.position,
        age: additionalData.age,
        number: additionalData.nr,
        sportnetUserId: sportnetUser._id,
        sportnetIssfId: additionalData.__issfId,
        isAthlete: true,
      });
      // Increment the ID counter
      id++;
    });

    // Loop through the `crew` array
    crew.forEach(({ position, sportnetUser }) => {
      // Create a new object with the required properties for each crew member
      destructedNominations.push({
        id,
        name: sportnetUser.name,
        position,
        sportnetUserId: sportnetUser._id,
        isCrew: true,
      });
      // Increment the ID counter
      id++;
    });

    // Assign the array of destructed nominations to newMembers
    this.newMembers = destructedNominations;
  },
  //     setIsLoading(loading) {
  //       this.isLoading = loading;
  //     },
  //     setHasError(error) {
  //       this.hasError = error;
  //     },
  //     setError(source, error) {
  //       this.setHasError(true);
  //       this.errors.push({ 'api: ': source, 'error: ': error });
  //     },
  //     /**
  //      * Resets stores state
  //      * @param {string} state Name of the state to be reseted
  //      * @param {string} value To which value it shoudl be reseted
  //      */
  //     $reset(state, value) {
  //       this[state] = value;
  //     },
  //   },
});

/**
 * Import HMR
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMembersStore, import.meta.hot));
}
