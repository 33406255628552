import { sk } from '@formkit/i18n';
import { generateClasses } from '@formkit/themes';

export default {
  config: {
    locales: { sk },
    locale: 'sk',
    classes: generateClasses({
      global: {
        // applies to all input types
        form: '$reset form',
        outer: '$reset form__group',
        wrapper: '$reset form__group__outer',
        inner: '$reset form__group__inner',
        input: '$reset form__input',
        label: '$reset form__label',
        messages: '$reset form__messages',
        message: '$reset form__messages__message',
      },
      text: {
        // only applies to text input type
        input: 'form__input--text',
      },
      email: {
        // only applies to email input type
        input: 'form__input--email',
      },
      submit: {
        input: '$reset form__button',
      },
    }),
  },
};
