<script setup>
import { defineComponent } from 'vue';

import sportnetLogo from '@/assets/images/sportnet.svg';
import futbalnetLogo from '@/assets/images/futbalnet.svg';

/**
 * Component Name
 */
defineComponent({
  name: 'ASource',
});

/**
 * Component Props
 */
defineProps({
  url: {
    type: String,
    required: true,
    default: '',
  },
  name: {
    type: String,
    required: true,
    default: '',
  },
});
</script>

<template>
  <div class="source">
    Zdroj informácii:
    <a :href="url" target="_blank">
      <img :src="sportnetLogo" alt="sportnetLogo" />
      <img :src="futbalnetLogo" alt="futbalnetLogo" />
    </a>
  </div>
</template>
