const retrieveUserIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.log('An error occurred while retrieving the user IP address:', error);
    return null;
  }
};

export default retrieveUserIP;
