/**
 * Import packages
 */
import { defineStore, acceptHMRUpdate } from 'pinia';
// import { useLocalStorage } from '@vueuse/core';

/**
 * Define Store
 */
export const useAppStore = defineStore(
  {
    /**
     * Store Name
     */
    id: 'app',

    /**
     * States
     */
    state: () => ({
      page: {
        title: '',
        path: '',
        pathName: '',
      },
      visibility: {
        header: true,
      },
      pageTransition: {
        name: 'router-view',
        mode: 'in-out',
      },
    }),

    /**
     * Getters
     */
    getters: {
      appName() {
        return import.meta.env.VITE_APP_NAME;
      },
      appTitle(state) {
        return state.page.title ? state.page.title + ' | ' + this.appName : this.appName || 'Application Title';
      },
      appDescription() {
        return import.meta.env.VITE_APP_DESCRIPTION || 'My beautiful website';
      },
    },

    /**
     * Actions
     */
    actions: {
      setPageTransition(value) {
        if ('default' === value) {
          this.pageTransition.name = 'router-view';
          this.pageTransition.mode = 'in-out';
        }

        if ('back' === value) {
          this.pageTransition.name = 'router-view-back';
          this.pageTransition.mode = '';
        }
      },
    },
  },
  {
    persist: true,
  }
);

/**
 * Import HMR
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
