<script setup>
/**
 * Import packages
 */
import { defineComponent } from 'vue';

/**
 * Import stores
 */
import { useAppStore } from '@/stores/app';

/**
 * Component Name
 */
defineComponent({
  name: 'TheMain',
});

/**
 * Variables
 */
const appStore = useAppStore();

/**
 * Functions
 */
const afterEnter = () => {
  window.scrollTo(0, 0);
};

const afterLeave = () => {
  console.log('left');
  appStore.setPageTransition('default');
};
</script>

<template>
  <main id="main" class="app__main">
    <div class="app__main__inner">
      <router-view v-slot="{ Component }">
        <!-- <transition
        :name="appStore.pageTransition.name"
        :mode="appStore.pageTransition.mode"
        @after-leave="afterLeave"
        @after-enter="afterEnter"
      > -->
        <component :is="Component" :key="$route.fullPath" />
        <!-- </transition> -->
      </router-view>
    </div>
  </main>
</template>

<style lang="scss">
$duration: 0.5s;

.transition {
  overflow: hidden;
}
.router-view,
.router-view-back {
  &-enter-active,
  &-leave-active {
    position: fixed;
    width: 100%;
    // background: white;
    min-height: 100vh;
    top: 0;
    z-index: 99;
  }
}
// router view
.router-view-enter-active {
  transition: transform $duration ease-in-out;
  z-index: 200;
  transform: translateX(100%);
}
.router-view-enter-to {
  z-index: 200;
  transform: translateX(0%);
}
.router-view-leave-active {
  z-index: -1;
}
.router-view-leave-to {
  z-index: -1;
}
// router view back

.router-view-back-leave-active {
  transition: transform $duration ease-in-out;
  z-index: 2;
  transform: translateX(0%);
}
.router-view-back-leave-to {
  z-index: 2;
  transform: translateX(100%);
}
</style>
