import { useAuthStore } from '@/stores/auth';

export default async function authGuard(to, from, next) {
  const authStore = useAuthStore();

  await authStore.checkAuthTokenExpiration();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.name !== 'Admin/Login' && !authStore.isAuthenticated) {
      authStore.returnUrl = to.name;

      next({ name: 'Admin/Login' });
    } else {
      next();
    }
  } else {
    next();
  }
}
