import { sportNetApi } from '.';

const teamAppSpace = import.meta.env.VITE_API_SPORTNET_TEAM_APP_SPACE;

export default {
  fetchTable(competitionId, partId, roundId) {
    if (roundId) {
      return sportNetApi().get(`/public/competitions/${competitionId}/parts/${partId}/results?roundId=${roundId}`);
    }
    return sportNetApi().get(`/public/competitions/${competitionId}/parts/${partId}/results`);
  },
  fetchTableForTeam() {
    return sportNetApi().get(`/team-competitions/${teamAppSpace}/latest-resultsTable`);
  },
};
