import { useAuthStore } from '@/stores/auth';

export default function registerCodeGuard(to, from, next) {
  const authStore = useAuthStore();

  const code = to.query.code;

  // Check if the code matches the one in the store
  if (code === authStore.registerCode) {
    // If it matches, go to the next route
    next();
  } else {
    // If it doesn't match, redirect to the login page
    next({ name: 'Admin/Login' });
  }
}
