<script setup>
/**
 * Import packages
 */
import { defineComponent, ref, watch } from 'vue';
import { useElementVisibility } from '@vueuse/core';

/**
 * Import components
 */
import MainHeader from '@/components/ui/MainHeader.vue';

/**
 * Import stores
 */
import { useAppStore } from '@/stores/app';

/**
 * Component Name
 */
defineComponent({
  name: 'TheHeader',
});

const appStore = useAppStore();

const refHeader = ref(null);

const targetIsVisible = useElementVisibility(refHeader);

watch(targetIsVisible, newValue => {
  appStore.visibility.header = newValue;
});
</script>

<template>
  <header ref="refHeader" class="app__header">
    <div class="app__header__inner">
      <MainHeader />
    </div>
  </header>
</template>
