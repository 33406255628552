import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);

Date.prototype.getWeek = function () {
  const date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  const yearStart = new Date(date.getFullYear(), 0, 1);
  return Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
};

const groupMatchesByWeek = matches => {
  const groups = {};

  for (const match of matches) {
    const startDate = new Date(match.startDate);
    const week = startDate.getWeek();
    const year = startDate.getFullYear();
    const date = startDate.toISOString().split('T')[0];

    if (!groups[`${year}-${week}`]) {
      groups[`${year}-${week}`] = { week, year, matches: {} };
    }

    if (!groups[`${year}-${week}`].matches[date]) {
      groups[`${year}-${week}`].matches[date] = [];
    }

    groups[`${year}-${week}`].matches[date].push(match);
  }

  return Object.values(groups);
};

export default groupMatchesByWeek;
