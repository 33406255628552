import { useErrorsStore } from '@/stores/errors';

export function errorsPlugin({ pinia }) {
  pinia.use(({ store }) => {
    const errorsStore = useErrorsStore();

    store.setError = async (error, method) => {
      await errorsStore.addError(error, store, method);
    };
  });
}
