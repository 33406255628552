<script setup>
/**
 * Import packages
 */
import { computed, watch, ref } from 'vue';
import { useHead } from '@vueuse/head';

import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';

import tjLogo from '@/assets/images/teams/tj-oravska-lesna.webp';

/**
 * Import components
 */
import TheFooter from '@/components/layout/TheFooter.vue';
import TheHeader from '@/components/layout/TheHeader.vue';
import TheMain from '@/components/layout/TheMain.vue';
import TheNavigation from '@/components/layout/TheNavigation.vue';
import TheAdminNavigation from '@/components/layout/admin/TheNavigation.vue';
import { onMounted } from 'vue';

/**
 * Variables
 */
const appStore = useAppStore();
const authStore = useAuthStore();

/**
 * Computed Variables
 */
const appName = computed(() => appStore.appName);
const appTitle = computed(() => appStore.appTitle);
const appDescription = computed(() => appStore.appDescription);
const isUserLoggedIn = computed(() => {
  return authStore.isAuthenticated;
});

useHead({
  title: appTitle,
  meta: [
    { name: 'description', content: appDescription },

    { name: 'author', content: 'fery.sk' },

    { name: 'twitter:card', content: appTitle },
    { name: 'twitter:title', content: appName },
    { name: 'twitter:description', content: appDescription },
    { name: 'twitter:image', content: tjLogo },

    { property: 'og:title', content: appName },
    { property: 'og:site_name', content: appTitle },
    { property: 'og:type', content: 'website' },
    { property: 'og:image', content: tjLogo },
    { property: 'og:description', content: appDescription },
  ],
});

const selectedTheme = useLocalStorage('selectedTheme', 'dark');
const selectedColor = useLocalStorage('selectedColor', 'yellow');

const applyThemeAndColor = () => {
  document.documentElement.setAttribute('data-theme', selectedTheme.value);
  document.documentElement.setAttribute('data-color', selectedColor.value);
};

watch([selectedTheme, selectedColor], () => {
  applyThemeAndColor();
});

onMounted(applyThemeAndColor);

function useLocalStorage(key, defaultValue) {
  const value = ref(localStorage.getItem(key) || defaultValue);
  watch(value, newValue => {
    localStorage.setItem(key, newValue);
  });
  return value;
}
</script>
<template>
  <TheAdminNavigation v-if="isUserLoggedIn" />
  <TheHeader />
  <Transition name="bounce" appear>
    <TheNavigation />
  </Transition>
  <!-- <div>
    <label>
      <input v-model="selectedTheme" type="radio" value="light" />
      Light
    </label>
    <label>
      <input v-model="selectedTheme" type="radio" value="dark" />
      Dark
    </label>
    <select v-model="selectedColor">
      <option value="blue">Blue</option>
      <option value="gold">Gold</option>
      <option value="yellow">Yellow</option>
      <option value="purple">Purple</option>
    </select>
  </div> -->
  <TheMain />
  <TheFooter />
</template>

<style lang="scss">
@import '@/assets/scss/app';
</style>
