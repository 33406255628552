// store/error.js
import { defineStore, acceptHMRUpdate } from 'pinia';
import { isEmpty } from 'lodash';

import errorsAPI from '@/api/errors.js';

import observePerformance from '@/utils/observePerformance';
import retrieveUserIP from '@/utils/retrieveUserIP';

export const useErrorsStore = defineStore({
  /**
   * Store Name
   */
  id: 'errors',

  /**
   * States
   */
  state: () => ({
    errors: [],
  }),

  /**
   * Getters
   */
  getters: {},

  /**
   * Actions
   */
  actions: {
    async addError(error, store, method) {
      const [userIP, performanceEntries] = await Promise.all([retrieveUserIP(), observePerformance()]);

      const errorDetails = {
        performanceEntry: performanceEntries.length > 0 ? performanceEntries[0].toJSON() : null,
        environment: import.meta.env.VITE_APP_ENV,
        code: error.code,
        name: error.name,
        message: error.message,
        request: {
          baseUrl: error.config.baseURL,
          url: error.config.url,
          method: error.config.method,
          parameters: error.config.data,
        },
        response: {
          status: error.response.status,
          statusText: error.response.statusText,
          message: error.response.data.message,
          trace: error.response.data.trace,
        },
        source: { name: store.$id, action: method },
        datetime: new Date().toISOString(),
        user: {
          ip: userIP,
          browser: window.navigator.userAgent.split(/\s(?=\w+\/)/),
          brands: window.navigator.userAgentData?.brands || null,
          mobile: window.navigator.userAgentData?.mobile,
          platform: window.navigator.userAgentData?.platform || null,
        },
      };

      this.errors.push(errorDetails);

      this.saveError(errorDetails);
    },

    async saveError(error) {
      try {
        await errorsAPI.createError(error);

        await this.sendStoredErrors();
      } catch (e) {
        await this.saveErrorToLocal(error);
      }
    },

    async saveErrorToLocal(error) {
      try {
        const storedErrors = JSON.parse(localStorage.getItem('storedErrors')) || [];
        storedErrors.push(error);
        localStorage.setItem('storedErrors', JSON.stringify(storedErrors));
      } catch (e) {
        console.error('Error saving error to local storage:', e);
      }
    },

    async sendStoredErrors() {
      const storedErrors = JSON.parse(localStorage.getItem('storedErrors')) || [];

      if (!isEmpty(storedErrors)) {
        await errorsAPI.createError(storedErrors);

        // Clear stored errors after successful sending
        localStorage.removeItem('storedErrors');
      }
    },
  },
});

/**
 * Import HMR
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useErrorsStore, import.meta.hot));
}
