<script setup>
/**
 * Import packages
 */
import { defineComponent } from 'vue';

/**
 * Component Name
 */
defineComponent({
  name: 'TheFooter',
});

/**
 * Variables
 */
const currentYear = new Date().getFullYear();

const title = import.meta.env.VITE_APP_NAME;
</script>

<template>
  <footer class="app__footer">
    <div class="app__footer__inner">
      <div class="copyright">&copy; {{ currentYear }} {{ title }} | Vytvoril <a href="https://fery.sk">Fery.sk</a></div>
    </div>
  </footer>
</template>
