const getDayOfYear = (dateFrom, isLastDay) => {
  const startDate = new Date(dateFrom);

  startDate.setMonth(0); // Set the month to January (0 is used because months are zero-based)

  if (isLastDay) {
    startDate.setFullYear(startDate.getFullYear() + 1);
    startDate.setDate(0); // Set the date to 0 to get the last day of the previous month
  } else {
    startDate.setDate(1); // Set the date to 1 to get the first day of the current month
  }

  return startDate.toISOString();
};

export default getDayOfYear;
