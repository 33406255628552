<script setup>
/**
 * Import packages
 */
import { defineComponent, inject } from 'vue';

/**
 * Component Name
 */
defineComponent({
  name: 'VTab',
});

/**
 * Component Props
 */
defineProps({
  title: {
    type: String,
    default: 'Tab',
  },
});

const selectedTitle = inject('selectedTitle');
</script>

<template>
  <div v-show="selectedTitle === title" class="tabs__content">
    <slot />
  </div>
</template>
