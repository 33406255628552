import { defineStore, acceptHMRUpdate } from 'pinia';
import { has, hasIn, isEmpty } from 'lodash';

import matchesAPI from '@/api/matches.js';

import groupMatchesByWeek from '@/helpers/groupMatchesByWeek';

export const useMatchesStore = defineStore(
  {
    id: 'matches',

    state: () => ({
      matches: [],
      teamMatches: [],
      isLoading: true,
      hasError: false,
      errors: [],
    }),

    getters: {
      results(state) {
        return groupMatchesByWeek(state.matches.filter(match => match.closed && match.teams.length === 2).reverse());
      },
      fixtures(state) {
        return groupMatchesByWeek(
          // state.matches.filter(match => !match.closed && new Date(match.startDate) >= new Date())
          state.matches.filter(match => !match.closed && match.teams.length === 2)
        );
      },
      getTeamMatches: state => teamName =>
        state.matches.filter(match => match.teams.length === 2 && match.teams.some(team => team.name === teamName)),
    },

    actions: {
      /**
       * Fetches all matches for a given competition and part from the API, filters out matches where at least one team has resigned,
       * and sets the matches state to the filtered array.
       * @param {String} competitionId The ID of the competition to fetch matches for.
       * @param {String} partId The ID of the part to fetch matches for.
       */
      async fetchMatches(competitionId, partId) {
        try {
          // Set the loading state to true before making the API request
          this.loading = true;

          // Initialize an empty array to hold the matches
          const matches = [];

          // Fetch the first batch of matches from the API
          let response = await matchesAPI.fetchMatches(competitionId, partId);

          // While there are more matches to fetch...
          while (response.data.nextOffset !== null) {
            // Add the matches from the current API response to the matches array
            matches.push(...response.data.matches);

            // Fetch the next batch of matches from the API
            response = await matchesAPI.fetchMatches(competitionId, partId, response.data.nextOffset);
          }

          // Add the final batch of matches from the last API response to the matches array
          matches.push(...response.data.matches);

          // Filter the matches based on the team resignation status
          const filteredMatches = matches.filter(match => {
            return match.teams.every(team => {
              return !team.resignation;
            });
          });

          // Set the matches state to the filtered array
          this.matches = filteredMatches.filter(match => match.teams.length === 2);
        } catch (error) {
          console.log(error);
        } finally {
          // Set the loading state to false after the API request is complete
          this.loading = false;
        }
      },
      async fetchTeamMatchesByDate(competitionId, teamId, date, isClosed) {
        const existingMatchObject = this.teamMatches.find(matchObj => matchObj.teamId === teamId);

        if (existingMatchObject && existingMatchObject.lastMatch && existingMatchObject.nextMatch) {
          return; // Already have both match data for this team, no need to continue.
        }

        try {
          let response = await matchesAPI.fetchTeamMatchesByDate(competitionId, teamId, date, isClosed);

          let matchData = response.data.matches;
          let match = {};

          if (matchData.length === 0) {
            match = {};
          } else if (matchData.length !== 1 && matchData[0].teams.length <= 1) {
            match = matchData[1];
          } else {
            match = matchData[0];
          }

          let newMatchObject = existingMatchObject || { teamId };

          if (isClosed) {
            newMatchObject.lastMatch = match;
          } else {
            newMatchObject.nextMatch = match;
          }

          if (!existingMatchObject) {
            this.teamMatches.push(newMatchObject);
          }
        } catch (error) {
          console.log(error);
        }
      },
      setIsLoading(loading) {
        this.isLoading = loading;
      },
      setHasError(error) {
        this.hasError = error;
      },
      setError(source, error) {
        this.setHasError(true);
        this.errors.push({ 'api: ': source, 'error: ': error });
      },
      /**
       * Resets stores state
       * @param {string} state Name of the state to be reseted
       * @param {string} value To which value it shoudl be reseted
       */
      $reset(state, value) {
        this[state] = value;
      },
    },
  },
  {
    // this will be read by a plugin later on
    debounce: {
      // debounce the action searchContacts by 300ms
      fetchMatches: 3000,
    },
  }
);

/**
 * Import HMR
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMatchesStore, import.meta.hot));
}
