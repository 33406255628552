<script setup>
/**
 * Import packages
 */
import { defineComponent } from 'vue';

/**
 * Import components
 */
import AdminMainMenu from '@/components/ui/admin/MainMenu.vue';

/**
 * Component Name
 */
defineComponent({
  name: 'TheAdminNavigation',
});
</script>

<template>
  <nav class="app__navigation app__navigation--admin">
    <div class="app__navigation__inner">
      <AdminMainMenu />
    </div>
  </nav>
</template>
