import { isEmpty } from 'lodash';

import { useCompetitionStore } from '@/stores/competition';
import { useMatchStore } from '@/stores/match';
import { useMatchesStore } from '@/stores/matches';
import { useMembersStore } from '@/stores/members';
import { usePostsStore } from '@/stores/posts';
import { useTableStore } from '@/stores/table';

export async function fetchAll(to, from, next) {
  const competitionStore = useCompetitionStore();
  const matchesStore = useMatchesStore();
  const postsStore = usePostsStore();
  const tableStore = useTableStore();

  if (isEmpty(competitionStore.competition)) {
    competitionStore.setIsLoading(true);

    try {
      await Promise.all([
        competitionStore.fetchCompetition(),
        competitionStore.fetchClubCompetitions(),
        competitionStore.fetchSeasons(),
        // postsStore.fetchPosts(),
      ]);
    } catch (error) {
      // console.error(error);
    }

    if (competitionStore.hasError) {
      competitionStore.setIsLoading(false);
      return next();
    }

    if (!isEmpty(competitionStore.competition)) {
      try {
        // await postsStore.fetchPosts();
        await tableStore.fetchTable(
          competitionStore.competition._id,
          competitionStore.competition.resultsTable.competitionPartId,
          null
        );
        // await tableStore.fetchTable(
        //   competitionStore.competition._id,
        //   competitionStore.competition.resultsTable.competitionPartId,
        //   competitionStore.getPreviousRound._id
        // );
        await matchesStore.fetchMatches(
          competitionStore.competition._id,
          competitionStore.competition.resultsTable.competitionPartId
        );
      } catch (error) {
        console.error(error);
      } finally {
        competitionStore.setIsLoading(false);
      }
    }
  }
  next();
}

export async function fetchCompetition(to, from, next) {
  const competitionStore = useCompetitionStore();

  if (!isEmpty(competitionStore.competition)) {
    try {
      await Promise.all([competitionStore.fetchClubCompetitions()]);
    } catch (error) {
      console.error(error);
    } finally {
      competitionStore.setIsLoading(false);
    }
  }
  next();
}

export async function fetchMatches(to, from, next) {
  const competitionStore = useCompetitionStore();
  const matchesStore = useMatchesStore();

  if (!isEmpty(competitionStore.competition)) {
    matchesStore.setIsLoading(true);

    try {
      await matchesStore.fetchMatches(
        competitionStore.competition.competitionId,
        competitionStore.competition.resultsTable.competitionPartId
      );
    } catch (error) {
      console.error(error);
    } finally {
      matchesStore.setIsLoading(false);
    }
  }
  next();
}

export async function fetchMatch(to, from, next) {
  const matchStore = useMatchStore();

  matchStore.setIsLoading(true);

  try {
    await matchStore.fetchMatch(to.params.matchId);
  } catch (error) {
    console.error(error);
  } finally {
    matchStore.setIsLoading(false);
  }
  next();
}

export async function fetchPosts(to, from, next) {
  const postsStore = usePostsStore();

  postsStore.setIsLoading(true);

  try {
    await postsStore.fetchPosts();
  } catch (error) {
    console.error(error);
  } finally {
    postsStore.setIsLoading(false);
  }
  next();
}

export async function fetchPost(to, from, next) {
  const postsStore = usePostsStore();

  postsStore.setIsLoading(true);

  try {
    await postsStore.fetchPost(to.params.postId);
  } catch (error) {
    console.error(error);
  } finally {
    postsStore.setIsLoading(false);
  }
  next();
}

export async function fetchRandomPhotos(to, from, next) {
  const postsStore = usePostsStore();

  try {
    await postsStore.fetchRandomPhotos();
  } catch (error) {
    console.error(error);
  }
  next();
}

export async function fetchTable(to, from, next) {
  const competitionStore = useCompetitionStore();
  const tableStore = useTableStore();

  if (!isEmpty(competitionStore.competition)) {
    tableStore.setIsLoading(true);

    try {
      await tableStore.fetchTable(
        competitionStore.competition.competitionId,
        competitionStore.competition.resultsTable.competitionPartId,
        null
      );
      await tableStore.fetchTable(
        competitionStore.competition.competitionId,
        competitionStore.competition.resultsTable.competitionPartId,
        competitionStore.getPreviousRound._id
      );
    } catch (error) {
      console.error(error);
    } finally {
      tableStore.setIsLoading(false);
    }
  }
  next();
}
