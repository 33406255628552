import Api, { sportNetApi } from '.';

const appSpace = import.meta.env.VITE_API_SPORTNET_APP_SPACE;

export default {
  fetchMatch(matchId) {
    return sportNetApi().get(`/matches/${matchId}?noCache=0`);
  },
  fetchMatches(competitionId, partId, offset) {
    if (offset) {
      return sportNetApi().get(
        `/public/${appSpace}/competitions/${competitionId}/parts/${partId}/matches?limit=100&offset=${offset}&sorter=dateFromAsc`
      );
    }
    return sportNetApi().get(
      `/public/${appSpace}/competitions/${competitionId}/parts/${partId}/matches?limit=100&sorter=dateFromAsc`
    );
  },
  fetchTeamMatchesByDate(competitionId, teamId, date, isClosed = false) {
    const dateParam = isClosed ? 'dateBefore' : 'dateAfter';

    return sportNetApi().get(
      `/public/${appSpace}/teams/${teamId}/competitions/${competitionId}/matches?limit=2&closed=${isClosed}&${dateParam}=${date}`
    );
  },
  createMatch(match) {
    return Api().post(`/match`, match);
  },
};
