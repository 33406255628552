import { sportNetApi } from '.';

// Define constants for the app space and team app space
const appSpace = import.meta.env.VITE_API_SPORTNET_APP_SPACE;
const teamID = import.meta.env.VITE_API_SPORTNET_TEAM_ID;
const teamAppSpace = import.meta.env.VITE_API_SPORTNET_TEAM_APP_SPACE;
const competitionID = import.meta.env.VITE_API_SPORTNET_COMPETITION_ID;

// Create an instance of the sportNetApi function
const api = sportNetApi();

// Define functions that make API calls using the api instance
export default {
  fetchClubCompetitions() {
    return api.get(`/club/${teamAppSpace}/competitions`);
  },
  // Fetch a list of seasons
  fetchSeasons() {
    return api.get(`/public/${teamAppSpace}/played-seasons`);
  },

  // Fetch the latest results table for the current competition
  fetchCompetition() {
    return api.get(`/public/${appSpace}/teams/${teamID}/competitions/${competitionID}`);
  },

  // Fetch the latest results table for the current competition
  fetchCurrentCompetition() {
    return api.get(`/team-competitions/${teamAppSpace}/latest-resultsTable`);
  },
};
